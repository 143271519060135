import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import starIcon from '../../assets/images/star-icon.png'

const GeneralFaqs = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title ptb-20">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        General FAQs
                </span> 
                </div>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. What are the advantages of working with ITSAS?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Here are some advantages of working with ITSAS company: 
                                </p>
                                <ul>
                                    <li>ITSAS is based in two Ukrainian IT cluster cities - Lahore, Mumbai and London, so our services cost less than Western Europe or US companies offer. However, the quality of our products is as high as theirs.</li>
                                    <li>Our team consists of the highly qualified professionals that produce outstanding products to our clients.</li>
                                    <li>Our Sales Managers will make a free estimation of your future project.</li>
                                    <li>You’ll get a comprehensive consultation from our Project Managers about your project.</li>
                                    <li>We also offer a post-release support and promotion services for our clients.</li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. What do I say to my investors if they don't want me to outsource my company's software development?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    In case you are a startup, there is a great number of reasons why you should outsource software development:
                                </p>
                                <ul>
                                    <li>Software development is not your key competency</li>
                                    <li>You are planning to hire developers to your company but you want to have an MVP first and validate your idea before you make large investment</li>
                                    <li>The outsourcing company you are hiring has an outstanding track record and charges less than an in-house team of developers.</li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </section>
    );
}

export default GeneralFaqs