import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import starIcon from '../../assets/images/star-icon.png'

const MaintenanceFaqs = () => {
    return (
        <section className="solutions-area pt-60 pb-70  bg-f1f8fb">
            <div className="container">
            <div className="section-title ptb-20">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        Maintenance FAQs
                    </span> 
                </div>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. What support will I get after the initial project is complete?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                We guarantee that you are covered for all possible malfunctions caused by our team. We also offer extended maintenance periods that ensure you being covered after the project release.
                                </p> 
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. Will you price gouge if I need additional work?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                No, we will not. Any additions are priced as separate mini-projects which will not cost you a lot.
                                </p> 
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q3. What is a bug?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                A bug is a piece of your product's functionality that doesn't work as it was required in the project documentation. ITSAS undertakes the responsibility to deliver you the high-quality product with as few bugs as possible. Revisions, layout changes or the changes based on user feedback are not considered as bugs.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q4. What is a revision?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                When during the development process our client wants to make minor changes into the product functionality, we eagerly perform the revisions to deliver the right product. However, we may restrict the number of revisions in order not to go beyond time limits.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q4. What is a change request?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                It’s a formal request of making changes in the project. Any change is assessed and approved by the Project Manager. If the change request is approved, it will affect the initial project plan and, therefore, the entire project scope. Change requests are clearly documented will all the important details.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        
                    </Accordion>
                </div>
            </div>
        </section>
    );
}

export default MaintenanceFaqs