import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import starIcon from '../../assets/images/star-icon.png'

const CostFaqs = () => {
    return (
        <section className="solutions-area pt-60 pb-70">
            <div className="container">
            <div className="section-title ptb-20">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        Cost FAQs
                    </span> 
                </div>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. How much will my project cost? How much does it cost to develop an app?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                ITSAS aims to deliver outstanding projects to our customers, not to make big money. That’s why the cost of your project will depend only on the development process cost. It usually includes the complexity and duration of your project, and the type of technologies used.
                                </p>
                                <p>
                                Here are approximate estimates for different types of projects:
                                </p>
                                <ul>
                                    <li>Small projects (an app with 4-5 key features, not counting static content or sign in): $5-$15K</li>
                                    <li>Middle-sized projects (an app with 6-9 key features, not counting static content or sign in): $20-40K</li>
                                    <li>Big projects (an app with 10-15 key features, not counting static content or sign in): $50K and more</li>
                                </ul>
                                <p>
                                What’s more, our company offers clients fixed priced projects, so there is no risk for you to spend more money in case of overruns during the development process.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. Can I hire ITSAS developers by the hour?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                You can contact us and we’ll discuss everything you want to know about our development service offers and how you can employ them.
                                Our e-mail address: info@ITSAS.com
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q3. Are the sales managers real people of just chat bots?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Be sure, everyone who talks to you or texts you is real. ITSAS has talented and professional sales engineers to answer all your questions.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q4. This is outside my budget. Are you really worth the cost?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                In reality, the question is about how much you value your investments. The global market of IT outsourcing can offer the options at lower costs from the less experienced talents’ pool. No wonder, many of the solutions created by them perform poorly. When taking a buying decision, get ready that you’ll get what you’re paying for.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q5. I don't have enough budget for my project. Can we partner for equity?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                I'm afraid, no.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q6. Why can't you give me an estimate for the entire project right away?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                The pre-operational stage that normally includes planning, business analysis, and UI/UX design, isn’t less important than the development of your product features. Failure at this stage inevitably leads to a less efficient and more time-consuming implementation of your project. So, precise and viable estimation doesn’t tolerate the haste.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q7. Can we work under a fixed-price contract?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes, indeed. The type of contract we give preference to is a fixed-price contract due to its cost effectiveness and lower risks.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q8. Will you provide maintenance and support of my application? Do I need any ongoing costs?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes, we do. There are a few possible ways of the maintenance and support services: on-demand and full-time.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </section>
    );
}

export default CostFaqs