import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import starIcon from '../../assets/images/star-icon.png'

const EngineeringFaqs = () => {
    return (
        <section className="solutions-area pt-60 pb-70  bg-f1f8fb">
            <div className="container">
            <div className="section-title ptb-20">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        Engineering FAQs
                    </span> 
                </div>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. Who are the engineers working on my project?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                All developers at ITSAS have the best qualifications and skills. We’re confident about our developers because all of them are the best graduates of our trainee programs. They have a great experience of working on various projects from small to big, from easy to complex. Developers at ITSAS keep up with modern technologies and our employees constantly improve their qualifications. Now, our developers specialize in:
                                </p>
                                <ul>
                                    <li>Web and Mobile (iOS and Android) Development</li>
                                    <li>Cloud Computing</li>
                                    <li>AI and Machine Learning technologies</li>
                                    <li>Virtual Reality</li>
                                    <li>iBeacon technology</li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. Who are the technical project manager?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Our team of Project Managers consists of experienced developers and skilled Product Managers who know everything about modern technologies and how to employ them. They specialize in HTML, CSS, JS, PHP, MySQL, CMS (Drupal, Joomla, Wordpress), С, С++, С#, Delphi 10, SCADA (TraceMode, WinCC).
                                </p>
                                <p>
                                Moreover, our project managers not only have technical qualifications, they know how to manage the team and the development process, so your project will be created at the highest level. ITSAS’s team of Project Managers include: 
                                </p>
                                <ul>
                                    <li>an Android developer with a minimum 3-year-experience of development;</li>
                                    <li>a Web developer with a minimum 5-year-experience of development, plus over a year experience of working as an engineering technician;</li>
                                    <li>an iOS developer with over 2 years of experience of development;</li>
                                    <li>two Product Managers with an experience of working in large product companies;</li>
                                    <li>an alumnus of a top UK university having MBA degree, who has an experience in managing the own company, understands business processes and business development requirements.</li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q3. How good are your developers?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                ITSAS’s recruiting team work hard to hire only the top tech talents: best graduates from specialized universities and experienced developers from top companies. Moreover, many of our employees had successfully undertaken the internship at ITSAS to become full-fledged members of our team.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q4. Will I have a dedicated team that works exclusively on my project?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Normally, we assign the definite team to a project so that they could devote their time and efforts to this exact project solely. However, under certain unforeseen circumstances (seek leaves, vacations), we have to substitute or change the members of the project team. We surely inform our clients about that and agree upon the details.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q5. How does ITSAS ensure the quality of the engineering for my project?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                First of all, ITSAS team includes only highly qualified developers which are the best graduates of our trainee programs, so we’re confident about their skills. Also, your project will be managed by a professional Project Manager who will keep you informed on how the development process of your project is going.
                                </p>
                                <p>
                                In addition, have an experienced QA team who’ll do their best to make sure your product works well.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </section>
    );
}

export default EngineeringFaqs