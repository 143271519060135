import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import starIcon from '../../assets/images/star-icon.png'

const ConfidentialityFaqs = () => {
    return (
        <section className="solutions-area pt-60 pb-70">
            <div className="container">
            <div className="section-title ptb-20">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        Confidentiality FAQs
                    </span> 
                </div>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. Do I own the legal rights to technology built by ITSAS teams?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes. As our client, you own any exclusive proprietary code and designs implemented in your delivered project.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. How can I be sure my project is kept confidential?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                We take all required precautions to keep your project secured. Only developers, who have signed a strict NDA with us, know the details of your project.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        
                    </Accordion>
                </div>
            </div>
        </section>
    );
}

export default ConfidentialityFaqs