import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import starIcon from '../../assets/images/star-icon.png'

const DesignFaqs = () => {
    return (
        <section className="solutions-area pt-60 pb-70">
            <div className="container">
            <div className="section-title ptb-20">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        Design FAQs
                    </span> 
                </div>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. Can you integrate my design?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                No problem. We can easily integrate the design created by any designer to your choice. If you provide us with PSD or SKETCH formats of your design, we will integrate it completely free of charge.
                                </p> 
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. Do you also provide design services?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes. ITSAS company has a team of creative and skilled designers. In case you have your vision on how your product’s design must look, we’ll introduce you to our designers, so you could discuss everything in details.
                                </p>
                                <p>
                                However. this is not the only option. If you don’t feel like managing design process, we’ll manage it ourselves giving you regular updates the process progress.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q3. What kind of design work do you do?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                The scope of our design services includes the integration of existing designs or building custom designs of any level of complexity.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        
                    </Accordion>
                </div>
            </div>
        </section>
    );
}

export default DesignFaqs