import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import starIcon from '../../assets/images/star-icon.png'

const ProcessFaqs = () => {
    return (
        <section className="solutions-area pt-60 pb-70  bg-f1f8fb">
            <div className="container">
            <div className="section-title ptb-20">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        Process FAQs
                    </span> 
                </div>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. Can you integrate my design?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                You are welcome to read the detailed story about the development process at ITSAS.
                                </p> 
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. How do I start a project?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                You can send us a message, giving details on your project’s idea and leave your contacts, so we could reach you.
                                </p>
                                <p>
                                Our Account Manager will contact you, so you could set up a meeting with our Business Analysts and Account Managers (or online conference, in case you can’t see us in person), where we’ll provide an offer and quote so your project gets staffed, and we could start our work quickly.
                                </p> 
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q3. Will I have to manage the engineers?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                No. All management activities can be conducted by our Technical Project Managers. They are experienced engineers who will outline, staff, and manage the project throughout all stages of development. They also can carry out the CTO activities or work in association with your current CTO.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q4. What happens when my project ends?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                After the product is built and we made sure it’s of the best quality, your Project Manager sends you the complete deliverables (this can the designs or zip file with your project code). However, at this point we don’t finish up the relationship with our clients. Whenever they decide to scale their project or develop additional features, they are welcome to turn to us.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        
                    </Accordion>
                </div>
            </div>
        </section>
    );
}

export default ProcessFaqs