import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import starIcon from '../../assets/images/star-icon.png'

const ServicesFaqs = () => {
    return (
        <section className="solutions-area pt-60 pb-70 bg-f1f8fb">
            <div className="container">
            <div className="section-title ptb-20">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        Services FAQs
                    </span> 
                </div>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. I have an idea only. What makes sense as the next step?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                The next step is market research to make sure your idea has a working potential. Business analysis is also necessary to build a right business strategy. Next, all your requirements to your product should be well-documented in functional specification that will describe the technical methods of implementing your business goals.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. If I have design ready, can you start right from app development?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes, we can. We are ready to use your materials as a foundation of a future app. However, we will have to revise everything you will provide us with to make sure we eliminate the risks of failure. All possible downsides are improved by our experienced team members.
                            </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q3. What development services can I receive from ITSAS?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    ITSAS team delivers the best development solutions for Web and Mobile (iOS and Android), employing next technologies:
                                </p>
                                <ul>
                                    <li>Cloud Computing</li>
                                    <li>AI and Machine Learning technologies</li>
                                    <li>Virtual Reality</li>
                                    <li>iBeacon technology</li>
                                </ul>
                                <p>
                                    ITSAS provides not only development services but also Design and Marketing services.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q4. Can ITSAS build my app from scratch?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes. We have a great experience in creating products from the scratch, including mobile and web applications, and different web products. Our team headed by Project Managers can provide you with all development solutions from the shaping the idea to post-release support.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q5. Can ITSAS help improve my existing app?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes. ITSAS has already delivered projects based on existing applications. Here are the services we can offer to improve your app:
                                </p>
                                <ul>
                                    <li>develop a new set of features;</li>
                                    <li>create a new version of your app for another platform;</li>
                                    <li>make a redesign of your app;</li>
                                    <li>your project recovery (if you have a ghost project, we can help you make it work).</li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q6. Can ITSAS build my mobile app?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes. We provide mobile and web application development for different platforms including iOS and Android, and we deliver the right products that meet your requirements.
                                </p> 
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q7. Can ITSAS build my full-stack responsive web app?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes. Our team consists of highly qualified developers who work with various modern technologies, so they can create your project using only the best approaches and technologies.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </section>
    );
}

export default ServicesFaqs