import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import starIcon from '../../assets/images/star-icon.png'

const PaymentFaqs = () => {
    return (
        <section className="solutions-area pt-60 pb-70 bg-f1f8fb">
            <div className="container">
            <div className="section-title ptb-20">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        Payment Faqs
                    </span> 
                </div>
                <div className="faq-accordion">
                    <Accordion> 
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. What forms of payment do you accept?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                We accept all most common payment options, including : ACH, , and bank cards.
                                </p>
                                <ul>
                                    <li>ACH</li>
                                    <li>Wire transfers</li>
                                    <li>Bank cards</li> 
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        
                    </Accordion>
                </div>
            </div>
        </section>
    );
}

export default PaymentFaqs