import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import starIcon from '../../assets/images/star-icon.png'

const ClientsFaqs = () => {
    return (
        <section className="solutions-area pt-60 pb-70">
            <div className="container">
            <div className="section-title ptb-20">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        Clients FAQs
                    </span> 
                </div>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. What types of clients will ITSAS serve?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                We serve clients from the diversity of industries and of different scales: from innovative startups to private entrepreneurs to agencies.
                                </p> <p>
                                ITSAS team understands all business processes and we know how to make your business grow.
                                </p> 
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. Who are some of your clients?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                ITSAS successfully cooperates with various mid-sized businesses, startups and private clients. 
                                </p>
                                <p>
                                Check out our Portfolio to learn more about our clients and the projects we delivered.
                                </p> 
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q3. I love that you're doing! Can I be featured on your case studies page?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                That's great. We'll be happy to have you featured in one of our Case Studies. Send us a message and we'll discuss all the details.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q4. What forms of payment do you accept?
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                We accept all most common payment options, including ACH, wire transfers, and bank cards.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        
                    </Accordion>
                </div>
            </div>
        </section>
    );
}

export default ClientsFaqs