import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Banner from "../components/FAQs/Banner"
import ProjectStartArea from "../components/Index/ProjectStartArea"
import GeneralFaqs from "../components/FAQs/GeneralFaqs"
import ServicesFaqs from "../components/FAQs/ServicesFaqs"
import Cost from "../components/FAQs/CostFaqs"
import Engineering from "../components/FAQs/EngineeringFaqs"
import Confidentiality from "../components/FAQs/ConfidentialityFaqs"
import Design from "../components/FAQs/DesignFaqs"
import Process from "../components/FAQs/ProcessFaqs"
import Clients from "../components/FAQs/ClientsFaqs"
import Maintenance from "../components/FAQs/MaintenanceFaqs"
import Payment from "../components/FAQs/PaymentFaqs"

const FAQs = () => {
    return (
        <Layout>
        <Navbar />
        <Banner />  
        <GeneralFaqs/>
        <ServicesFaqs/>
        <Cost/>
        <Engineering/>
        <Design/>
        <Process/>
        <Clients/>
        <Maintenance/>
        <Confidentiality/>
        <Payment/>
        <ProjectStartArea />
        <Footer />
    </Layout>
    );
}

export default FAQs